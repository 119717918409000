import { CountUp } from "countup.js";

const countUpShow = new CountUp("show-count", 128);
const countUpSold = new CountUp("sold-count", 64);

if (window.innerWidth >= 1240) {
    setTimeout(() => {
        countUpShow.start();
        countUpSold.start();
    }, 1900);
} else {
    countUpShow.start();
    countUpSold.start();
}
